<template>
  <div id='wf-widgetspanel' class="wf-panel wf-widgetspanel">
    <div class="wf-panel-tab">
      <a href="javascript:;" class="tabitem current">控件</a>
    </div>
    <div class="wf-panel-body">
      <div
        v-for="(item, index) in components"
        :key="index"
        class="wf-widgetsitem"
        v-bind:data-index="index"
        v-bind:data-type="item.componentName"
        v-on:mousedown="start"
      >
        <label>
          {{ item.name }}
        </label>
        <i class="widgeticon" v-bind:class="item.componentName"></i>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import AppCompoentBase from '@/shared/component-base/app-component-base';

export default {
  mixins:[AppCompoentBase],
  name: "mainleft",
  data() {
    return {
      components: [
        {
          key: 1,
          name: "单行输入框",
          defaultLable: "单行输入框",
          defaultProps: "请输入",
          defaultImportant: false,//是否必填
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [],
          componentName: "textfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 2,
          name: "多行输入框",
          defaultLable: "多行输入框",
          defaultProps: "请输入",
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [],
          componentName: "textareafield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 3,
          name: "数字输入框",
          defaultLable: "数字输入框",
          defaultProps: "请输入",
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [],
          componentName: "numberfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "required",
            "important",
            "print",
            "uint",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 4,
          name: "下拉选择框",
          defaultLable: "下拉选择框",
          defaultProps: "请选择",
          defaultImportant: false,
          defaultSync: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [
            { idx: 1, text: "选项1" },
            { idx: 2, text: "选项2" },
            { idx: 3, text: "选项3" },
          ],
          componentName: "ddselectfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "options",
            "sync",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 5,
          name: "多选框",
          defaultLable: "多选框",
          defaultProps: "请选择",
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [
            { idx: 1, text: "选项1" },
            { idx: 2, text: "选项2" },
            { idx: 3, text: "选项3" },
          ],
          componentName: "ddmultiselectfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "options",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 6,
          name: "单选框",
          defaultLable: "单选框",
          defaultProps: "请选择",
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [
            { idx: 1, text: "选项1" },
            { idx: 2, text: "选项2" },
            { idx: 3, text: "选项3" },
          ],
          componentName: "radioselectfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "options",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 8,
          name: "附件",
          defaultLable: "附件",
          defaultOptions: [],
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          componentName: "ddattachment",
          supportSetting: ["label", "required", "important", "print", "field"],
        },
        {
          key: 9,
          name: "日期",
          defaultLable: "日期",
          defaultProps: "请选择",
          defaultImportant: false,
          defaultOptions: [],
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultFormat: "yyyy-MM-dd",
          componentName: "dddatefield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "dateformat",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 10,
          name: "日期区间",
          defaultLable: "开始时间",
          defaultProps: "请选择",
          defaultOptions: [],
          defaultImportant: false,
          defaultPrint: false,
          defaultAutorekonTime: false,//是否需要格式化
          fieldKey: "",
          textLength: "0",
          defaultSubtitle: "时长",
          componentName: "dddaterangefield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "required",
            "important",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 11,
          name: "多级联动",
          defaultLable: "多列选择",
          defaultProps: "请选择",
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [],
          componentName: "ddlevelselectfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "options",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
            "levelSelect",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
        {
          key: 12,
          name: "省市区",
          defaultLable: "省市区选择",
          defaultProps: "请选择",
          defaultImportant: false,
          defaultPrint: false,
          fieldKey: "",
          textLength: "0",
          defaultOptions: [],
          componentName: "ddlevelareaselectfield",
          supportSetting: [
            "field",
            "label",
            "placeholder",
            "options",
            "required",
            "important",
            "print",
            "canTheUserFillIn",
          ],
          // C端用户是否可填写,
          defaultCanTheUserFillIn: true,
        },
      ],
    };
  },
  methods: {
    start: function (e) {
      let obj = {};
      let dom = e.currentTarget;
      let index = dom.getAttribute("data-index");
      let actualLeft = dom.offsetLeft;
      let current = dom.offsetParent;
      let actualTop = dom.offsetTop;
      while (current !== null) {
        actualLeft += current.offsetLeft;
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      obj.componentName = dom.getAttribute("data-type");
      obj.componentText = dom.querySelector("label").innerText;
      obj.clientX = e.clientX;
      obj.clientY = e.clientY;
      obj.isstart = true;
      obj.componentView = this.components[index];
      console.log(obj);
      drag.$emit("movestart", obj);
    },
    //获取所有表单字段
    getAllFormField(){
      let _this = this;
      this.SendToService({
        url: '/api/services/app/FieldLibs/GetAllFields',
        method: 'GET',
        success (res) {
          // console.info(res);
          if(res && res.length > 0) {
            res.forEach(item => {
              _this.components.push(item);
            });
          }
        }
      });
    },
    SendToService(opts) {
        this.spinning = true;
        let options = {
            url: this.$apiUrl + opts.url,
            method: opts.method,
            data: opts.data,
            params: opts.params,
            headers: { "Content-Type": "application/json-patch+json" },
        };
        this.$api.request(options).then((res) => {
            this.spinning = false;
            if(opts.complete && typeof opts.complete == 'function') {
                opts.complete(res);
            }
            if (res.status == 200) {
                if (opts.success && typeof opts.success == 'function') {
                    opts.success(res.data);
                }
            } else {
                abp.message.error(res.statusText);
            }
        }).catch(e => {
            console.error(e);
            this.spinning = false;
            if(opts.complete && typeof opts.complete == 'function') {
                opts.complete(e);
            }
        });
    }
  },
  created() {
    this.getAllFormField(); 
  }
};
</script>

<!-- <style lang="less">
  @import "../../../../styles/common.less";
  #scrollbarStyle;
  .wf-widgetspanel {
    padding: 0 !important;
    margin: 20px 0 0 0 !important;
    .wf-panel-body {
      margin: 10px 0 0 0 !important;
      overflow-y: auto !important;
      height: 85vh !important;
    }
  }
</style> -->
