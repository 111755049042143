<template>
  <div id="app" class="wf-wrapper" v-on:mouseup="moveend" v-on:mousemove="move">
    <Header></Header>
    <div class="wf-main">
      <mainleft></mainleft>
      <setting></setting>

      <formcanvas
        ref="formcanvasref"
        :defaultComponents="defaultComponents"
        :onSave="saveForm"
        :onGoBack="goBack"
      ></formcanvas>
    </div>
    <dragging></dragging>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from "./components/header";
import mainleft from "./components/mainLeft";
import formcanvas from "./components/formcanvas";
import setting from "./components/setting";
import dragging from "./components/dragging";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import { AppComponentBase } from "@/shared/component-base";
export default {
  mixins: [AppComponentBase],
  name: "app",
  components: {
    Header,
    mainleft,
    formcanvas,
    setting,
    dragging,
  },
  data: function () {
    return {
      isstart: false,
      componentView: {},
      _serviceProxy: "",
      id: "",
      defaultComponents: {
        components: [],
      },
    };
  },
  methods: {
    move: function (e) {
      if (this.isstart) {
        document.querySelector("html").classList.add("wf-cursor-move");
        let obj = {
          componentName: this.componentView.componentName,
          clientX: e.clientX,
          clientY: e.clientY,
        };
        drag.$emit("moveInCanvas", obj);
        drag.$emit("move", e);
      }
    },
    moveend: function (e) {
      if (this.isstart) {
        let obj = {
          componentView: this.componentView,
        };
        drag.$emit("moveend", obj);
        this.isstart = false;
      }
    },
    async getData() {
      var res = await this._serviceProxy.getFormItem(this.id);

      let tranSet = this.tranSetFormData(res);
      this.defaultComponents = tranSet;
      // this.$refs.formcanvasref.defaultComponents = tranSet;
    },
    tranSetFormData(serviceData) {
      let baseDate = {
        components: [],
      };
      console.log(serviceData);
      baseDate.components = serviceData.map((value, index) => {
        const supportSetting = [
          "field",
          "label",
          "placeholder",
          "required",
          "important",
          "print",
          "canTheUserFillIn",
        ]
        let defaultOptions = [];
        let componentName = 'textfield';
        if (value.itemType === 11) {
          supportSetting.push('levelSelect');
          componentName = 'ddlevelselectfield';
          if (value.options?.length) {
              defaultOptions = JSON.parse(value.options[0].value);
          }
          } else {
            defaultOptions =
              value.options.length > 0
                ? value.options.map((c, i) => {
                    return {
                      idx: i,
                      text: c.value,
                    };
                  })
                : undefined;
        }
        return {
          componentName,
          defaultImportant: value.isRequire,
          defaultLable: value.name,
          defaultPrint: false,
          defaultProps: value.remark,
          fieldKey: value.key,
          idx: index,
          key: value.itemType,
          name: value.name,
          supportSetting,
          // C端用户是否可填写,
          defaultCanTheUserFillIn: value.canTheUserFillIn,
          textLength: value.maxLength,
          required: value.isRequire,
          defaultOptions,
        };
      });
      return baseDate;
    },
    async saveForm(item) {
      console.log(item);
      const _arr = item.components.filter(
        (item) => item.textLength === 0 || item.textLength === "0"
      );
      if (_arr.length) {
        this.$message.info(
          `${_arr[0].name}"${_arr[0].defaultLable}"的最大长度不能为0`
        );
        return;
      }
      let postData = {
        formId: this.id,
        formItemEdits: item.components.map((value, index) => {
          if (value.key == 11) {
            const opts = [{label: '', value: JSON.stringify(value.defaultOptions)}];
            return {
              name: value.defaultLable,
              itemType: value.key,
              options: value.defaultOptions
                ? JSON.stringify(opts)
                : "[]",
              canTheUserFillIn:
                value.defaultCanTheUserFillIn === undefined
                  ? false
                  : value.defaultCanTheUserFillIn,
              isRequire: value.defaultImportant,
              key: value.fieldKey,
              maxLength: value.textLength,
              status: 1,
              remark: value.defaultProps,
              collectionFormId: this.id,
              // sort: value.idx,
              sort: index,
            };
          }
          return {
            name: value.defaultLable,
            itemType: value.key,
            options: value.defaultOptions
              ? JSON.stringify(
                  value.defaultOptions.map((op, i) => {
                    return { label: op.text, value: op.text };
                  })
                )
              : "[]",
            canTheUserFillIn:
              value.defaultCanTheUserFillIn === undefined
                ? false
                : value.defaultCanTheUserFillIn,
            isRequire: value.defaultImportant,
            key: value.fieldKey,
            maxLength: value.textLength,
            status: 1,
            remark: value.defaultProps,
            collectionFormId: this.id,
            // sort: value.idx,
            sort: index,
          };
        }),
      };
      // console.log(postData);
      // return;
      await this._serviceProxy.addItem(postData);
      this.$notification["success"]({
        message: this.l("SavedSuccessfully"),
      });
      this.goBack();
      // this.$router.push({
      //   path: `/app/linggong/collectionform`,
      // });
    },
    goBack() {
      if (this.$route.query.from == "2") {
        this.$router.push({
          path: `/app/unnaturalperson/collectionform`,
        });
      } else {
        this.$router.push({
          path: `/app/linggong/collectionform`,
        });
      }
    },
  },

  created: function () {
    this._serviceProxy = new CollectionFormServiceProxy(
      this.$apiUrl,
      this.$api
    );
    let self = this;
    this.id = this.$route.params.id;
    drag.$on("movestart", function (obj) {
      self.isstart = true;
      self.componentView = obj.componentView;
    });
  },
  mounted: async function () {
    await this.getData();
  },
};
</script>
<style>
@import "style/design.css";
</style>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
